import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
const wrapRootElement = ({ element }) => (
    <ChakraProvider theme={theme}>
        {element}
    </ChakraProvider>
);

export default wrapRootElement;
