import { extendTheme } from "@chakra-ui/react";
import "./src/css/typography.css";

const theme = {
    fonts: {
        heading: "Xmas Sweater Stitch",
        body: "Chopin Script",
    },

    components: {
        Heading: {
            baseStyle: {
                fontWeight: 500,
            },
        },

        Button: {
            baseStyle: {
                textTransform: "uppercase",
                letterSpacing: "2px",
                fontFamily: "Xmas Sweater Stitch",
                fontWeight: "400",
            },

            variants: {
                outline: {
                    _hover: {
                        color: "black",
                    },
                },
            },
        },
    },
};

export default extendTheme(theme);
